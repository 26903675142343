body {
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

.nav__link-group {
  display: none;
}

/* 커라젤용 css 높이를 49vw로 한 이유가 xl 전환될때 자연스럽게 됨 */
.carousel__css {
  height: 49vw !important;
}

/*
    직접 home-intro 부분. 모바일용
*/
.home__link {
  width: 100%;
}

.home__intro_parent {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  width: 100%;
}

.home__intro_parent:hover > .home__intro_child {
  font-size: 45px;
}

.home__intro_child {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  color: #9e4f89;
  background-color: rgba(255, 255, 255, 0.5);

  font-weight: 600;
  font-size: xx-large;
  padding: 5px;

  height: 100%;

  transition: all 0.2s;
}

.home__intro_img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  object-position: middle;
}

/*home-youtube*/

.home__youtube_parent_pc {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: flex-end;
  align-items: flex-end;
}

.home__youtube_child {
  z-index: 1;
  margin-right: 6vw;
  margin-bottom: 2vw;
  height: 20vw;
  width: 28vw;
}

@media (min-width: 768px) {
  .disappear {
    display: flex !important;
  }

  .footer_display {
    display: inline !important;
  }

  /* 네비게이션 드롭다운 용 css */
  .nav {
    display: flex;
    background-color: #9e4f89;
  }

  .nav * {
    font: 500 15px sans-serif;
  }

  .nav__link {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: black !important;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    background-color: transparent;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.5s;
    border-radius: 10px;
  }

  .nav__link:hover {
    background-color: #7f304e;
    color: white !important;
  }

  .nav__link-group {
    background-color: white;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    border-radius: 10px;
  }

  .nav__link:hover .nav__link-group {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: opacity 0.2s, visibility 0.2s, transform 0.2s;
  }

  /* 홈 intro 부분 css */
  .home__intro_parent {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: flex-end;

    transition: transform 0.4s;
  }

  .home__intro_parent:hover {
    transform: scale(1.2);
  }

  .home__intro_parent:hover > .home__intro_child {
    height: 100%;
    color: white;
    font-size: xx-large;
  }

  .home__intro_child {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    color: black;
    background-color: rgba(255, 182, 193, 0.7);

    font-weight: 600;
    font-size: larger;
    padding: 5px;

    height: 40px;

    transition: all 0.5s;
  }

  .home__link {
    width: fit-content;
  }

  .home__intro_img {
    height: 100%;
  }

  .home__youtube_parent_pc {
    display: flex;
  }

  .home__youtube_parent_mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  .showing__cosmetic {
    display: none !important;
  }

  .headerMenu_css {
    /* width: 120px; */
    border-radius: 10px;
  }

  .board-lg-right {
    text-align: left !important;
  }

  .board-lg-center {
    text-align: center !important;
  }

  .footer-lg-right {
    justify-content: flex-end !important;
  }
}

@media (min-width: 1200px) {
  .carousel__css {
    height: 587px !important;
  }

  .headerBG__css {
    height: 169px !important;
  }

  .cssShapeBox_disappear {
    display: block !important;
  }

  .after_appear_cssShapeBox {
    text-align: right !important;
    background-color: transparent !important;
    border: transparent !important;
  }

  .br__appear {
    display: block !important;
  }

  .home__youtube_child {
    z-index: 1;

    margin-bottom: 40px;
    height: 204px;
    width: 361px;
  }
}

@media (min-width: 2001px) {
  .carousel__css {
    object-fit: contain !important;
    background-repeat: repeat-x;
    background-position-x: center;
  }

  .first_carousel {
    background-image: url('./components/Home/carousel_1.png');
  }

  .second_carousel {
    background-image: url('./components/Home/carousel_2.png');
  }

  .headerBG__css {
    object-fit: contain !important;
    background-image: url('./components/Header/header_sub_bg.png');
    background-repeat: repeat-x;
    background-position-x: center;
  }
}

.home__notice {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home__notice_parent:hover {
  text-decoration: underline;
}

.board__title:hover {
  text-decoration: underline;
}

/*사이드 네비게이션 용 css*/
.active.sideNavComponent {
  background-color: #7f304e !important;
  color: white !important;
  border-radius: 0px;
}

@media (max-width: 768px) {
  .sideNavComponent {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

/* spinner css */
.loaders-container {
  padding: 20px;
  margin: 0 auto;
  width: 700px;
  text-align: center;
  font-size: 0;
}
.loaders-container .spinner_container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  padding: 30px;
  width: 25%;
  height: 140px;
}

.spinner_circle {
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 10px solid #7f304e;
  border-top-color: transparent;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn-click:active {
  background-color: #bebdb8 !important;
}

.page-item.active > .page-link {
  background-color: #7f304e !important;
}

@media (max-width: 992px) {
  .company__motto {
    opacity: 0;
  }

  .company__motto_image {
    opacity: 0.5;
  }

  .company__motto_text {
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 1);
  }
}

.product__box {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out;
}

.product__box:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidemenu__hr {
  display: none;
  width: calc(100% - 20px);
  margin: 0 auto;
  border-color: rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px) {
  .sidemenu__hr {
    display: block;
  }
}
